.Body {
  transition: background-color 0.3s;
}

.Body.lightGreen {
  background-color: $lightGreen;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-250%);
  }
}

.Marquee {
  animation: marquee 15s linear;
  animation-iteration-count: infinite;
  z-index: 1;
}

.VimeoPlayer iframe {
  height: 100%;
  left: 0;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}

.ProjectBodySection {
  p {
    margin-bottom: 1.6666em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  b,
  strong {
    font-family: "Archivo Black", sans-serif;
    font-weight: 400;
  }

  a {
    font-weight: 700;
    font-style: normal;
    position: relative;
    text-decoration: none;
    transform: translateZ(0);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $lightGreen;
      transform: scaleX(0) scaleY(1.1);
      transform-origin: left center;
      transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      z-index: 1;
    }

    &:hover:before {
      transform: scaleX(1) scaleY(1.1);
    }

    span {
      position: relative;
      z-index: 2;
    }
  }

  a.AboutLink {
    &::before {
      background-color: $white;
    }
  }
}

.StorySection {
  p {
    margin-bottom: 1.6666em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
