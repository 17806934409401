:root {
  font: 62.5% Helvetica, Arial, Sans-serif;
}

* {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

html {
  height: 100%;
  overflow-y: scroll;
  position: relative;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  position: relative;
  background-color: $background-color;
  color: $text-color;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

em,
i {
  font-style: italic;
}

strong,
b {
  font-weight: bold;
}

input,
textarea {
  appearance: none;
  background-color: $white;
  border: 1px solid #444;
  border-radius: 0;
  color: $black;
  margin: 0;
  max-width: 100%;
  resize: none;
  width: 100%;
}

input[type='checkbox'] {
  appearance: none;
  box-shadow: none;
  width: auto;

  &:focus {
    outline: none;
  }
}

input[type='radio'] {
  appearance: none;
  box-shadow: none;
  width: auto;

  &:focus {
    outline: none;
  }
}

input:focus,
textarea:focus {
  outline-style: solid;
  outline-width: 2px;
}

select {
  appearance: none;
  border: 1px solid #aaa;
  margin: 0;
  padding: 0;
}

a {
  color: $text-color;
}

#root {
  min-height: 100vh;
  position: relative;
}
